import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row, Col, Spin, Card, Image } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ArrowRightOutlined } from "@ant-design/icons";
import Fade from "react-reveal/Fade";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
import HeaderAlternative from "./HeaderAlternative";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "bottom",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
        loading: false,
      });
    });
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  renderSearchBar() {
    const { dotPosition } = this.state;
    const { pexUnits } = this.props;
    return (
      <>
        {window.isPhone != true ? (
          <div
            classname="desktop-view"
            style={{
              backgroundColor: "#f3f5f8",
            }}
          >
            <section className="block-sl">
              <div
                style={{
                  backgroundImage: `url("/images/gwm-background-img.jpg")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100vw",
                  margin: "auto",
                  marginTop: "6em",
                  height: "100vh",
                  minHeight: "55em"
                }}
              ></div>
            </section>
            <Row id="brands"
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "6em",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <Fade delay={300}>
                  <h1
                    style={{
                      fontSize: "30px",
                      lineHeight: "0.2em",
                      color: "black",
                      fontWeight: 600,
                    }}
                  >
                    Explore The GWM Brands
                  </h1>
                </Fade>
              </Col>
            </Row>
            <Fade delay={600} bottom>
              <Row
                gutter={16}
                style={{
                  textAlign: "center",
                  maxWidth: "90em",
                  margin: "auto",
                  marginTop: "2em",
                }}
              >

                <div class="grid" style={{ margin: "auto" }}>
                  <figure class="effect-sadie">
                    <img
                      src="/images/gwm-hover-back.webp"
                      alt="img02"
                      style={{ opacity: 0 }}
                    />
                    <figcaption>
                      <h2>
                        {" "}
                        <img
                          src="/images/gwm-logo-back.png"
                          width={150}
                          style={{ margin: "auto", width: "4em" }}
                          alt="GWM-Logo"
                        />
                      </h2>
                      <p>
                        <ul>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "-1em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://gwmklerksdorp.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Klerksdorp
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.gwmpotchefstroom.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Potchefstroom
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.gwmmahikeng.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Mahikeng
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.gwmlichtenburg.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Lichtenburg
                            </a>
                          </li>
                        </ul>
                      </p>
                    </figcaption>
                  </figure>
                  <figure class="effect-sadie">
                    <img
                      src="/images/haval-hover-back.webp"
                      alt="img14"
                      style={{ opacity: 0 }}
                    />
                    <figcaption2>
                      <h2>
                        {" "}
                        <img
                          src="/images/haval-logo.svg"
                          style={{ margin: "auto", width: "6em" }}
                          alt="GWM-Logo"
                        />
                      </h2>
                      <p>
                        <ul>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "-1em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://havalklerksdorp.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Klerksdorp
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.havalpotchefstroom.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Potchefstroom
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.havalmahikeng.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Mahikeng
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.havallichtenburg.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Lichtenburg
                            </a>
                          </li>
                        </ul>
                      </p>
                    </figcaption2>
                  </figure>
                </div>
              </Row></Fade>
            <Row
              style={{
                maxWidth: "100vw",
                margin: "auto",
                marginTop: "5em",
                backgroundColor: "#282725",
                paddingTop: "6em",
                paddingBottom: "6em",
              }}
            >
              <div
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  display: "flex",
                }}
              >

                <Col
                  span={13}
                  style={{
                    backgroundImage: `url("/images/car-img.webp")`,
                    objectFit: "contain",
                    backgroundRepeat: "no-repeat",
                    height: "55em",
                  }}
                ></Col>
                <Col span={1}></Col>
                <Col
                  span={10}
                  style={{
                    paddingTop: "5em",
                  }}
                >
                  <Fade delay={600} bottom>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#e30613",
                      }}
                    >
                      | CARS.CO.ZA CONSUMER AWARDS{" "}
                    </h1>
                    <h1
                      style={{
                        fontSize: 33,
                        fontWeight: 600,
                        color: "white",
                        lineHeight: "1.18",
                      }}
                    >
                      GWM celebrates four semi-finalists in Cars.co.za <br />
                      Consumer Awards
                    </h1>
                    <p
                      style={{
                        fontSize: 18,
                        color: "white",
                      }}
                    >
                      GWM is celebrating the selection of four models within the
                      manufacturer’s comprehensive range in South Africa as
                      semi-finalists in the recently announced 2023/24 Cars.co.za
                      Consumer Awards motoring awards programme.
                      <br />
                      <br />
                      The Cars.co.za Consumer Awards recognises the best
                      purchasing propositions in 13 categories of the South
                      African new-vehicle market from all vehicles available in
                      the South African market on September 1, 2023. Two models
                      from the best-selling HAVAL JOLION range were chosen, as
                      well as the HAVAL H6 HEV and ORA 03 Super Lux – giving more
                      credence to GWM’s position as one of the top ten
                      manufacturers on the monthly vehicle sales charts.
                    </p></Fade>
                </Col>
              </div>
            </Row>

            <Row
              id="about"
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "7em",
              }}
            >

              <Col span={12}>
                <Fade delay={300} bottom>
                  <h1
                    style={{
                      fontSize: 15,
                      color: "#858690",
                      padding: "0em",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 25,
                        color: "black",
                      }}
                    >
                      |
                    </span>{" "}
                    ABOUT COMPANY{" "}
                  </h1>
                  <h1
                    style={{
                      fontSize: 46,
                      fontWeight: 600,
                      lineHeight: "1.18",
                    }}
                  >
                    GREAT WALL MOTOR COMPANY LIMITED
                  </h1>
                </Fade>
              </Col>

              <Col span={12}>
                <Fade delay={600} bottom>
                  <p
                    style={{
                      fontSize: 18,
                      color: "black",
                    }}
                  >
                    GWM has created an energy-intelligence-oriented forest
                    ecosystem, established the parallel development of hybrid,
                    pure electric, and hydrogen energy, and carried out the layout
                    of the entire industry chain in terms of intelligent driving,
                    intelligent cockpit, and intelligent chassis, and built an
                    industry-leading energy system of "photovoltaic + distributed
                    energy storage + centralized energy storage" has completed the
                    full value chain layout of "solar
                    energy-battery-hydrogen-vehicle power".
                  </p>
                </Fade>
              </Col>
            </Row>
            <Fade delay={300} bottom>
              <Row
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  marginTop: "8em",
                  borderTop: "1px solid #858690",
                  borderBottom: "1px solid #858690",
                }}
              >
                <Col
                  span={12}
                  style={{
                    borderRight: "1px solid #858690",
                    paddingTop: "2em",
                  }}
                >
                  <h1
                    style={{
                      fontSize: 46,
                      fontWeight: 600,
                      lineHeight: "1.18",
                      textTransform: "uppercase"
                    }}
                  >
                    Embark on a Journey of Innovation
                  </h1>
                  <p
                    style={{
                      fontSize: 18,
                      color: "black",
                      paddingTop: "1.2em",
                    }}
                  >
                    Experience groundbreaking technology and cutting-edge advancements in automotive engineering that drive our commitment to excellence.
                  </p>
                </Col>
                <Col
                  span={12}
                  style={{
                    paddingLeft: "2em",
                    paddingTop: "2em",
                    paddingBottom: "2em",
                  }}
                >
                  <h1
                    style={{
                      fontSize: 46,
                      fontWeight: 600,
                      lineHeight: "1.18",
                    }}
                  >
                    DISTRIBUTION NETWORK
                  </h1>
                  <p
                    style={{
                      fontSize: 18,
                      color: "black",
                      paddingTop: "1.2em",
                    }}
                  >

                    Discover our range of vehicles at GWM and HAVAL branches in Klerksdorp, Lichtenburg, Potchefstroom, and Mahikeng.
                    Our extensive distribution network ensures unparalleled access to our products and services.
                  </p>
                </Col>
              </Row>
            </Fade>
            <Fade delay={300} bottom>
              <Row
                gutter={24}
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  marginTop: "7em",
                  paddingBottom: "7em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      fontSize: 15,
                      color: "#858690",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 25,
                        color: "black",
                      }}
                    >
                      |
                    </span>{" "}
                    STEP INTO OUR BRANCHES
                  </h1>
                  <p
                    style={{
                      fontSize: 24,
                      color: "black",
                      paddingTop: "1em",
                    }}
                  >

                    Discover a World of Automotive Excellence at Our GWM and HAVAL Branches.
                  </p>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "4em",
                    }}
                  >
                    <span>
                      <a
                        href="https://gwmklerksdorp.megatheron.co.za/"
                        target="_blank"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          GWM Klerksdorp
                        </h1>
                      </a>
                      <a
                        href="https://gwmlichtenburg.megatheron.co.za/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          GWM Lichtenburg
                        </h1>
                      </a>
                      <a
                        href="https://gwmpotchefstroom.megatheron.co.za/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          GWM Potchefstroom
                        </h1>
                      </a>
                      <a
                        href="https://gwmmahikeng.megatheron.co.za/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          GWM Mahikeng
                        </h1>
                      </a>
                    </span>
                    <span
                      style={{
                        marginLeft: "3em",
                        paddingLeft: "3.5em",
                        borderLeft: "1px solid #858690",
                      }}
                    >
                      <a
                        href="https://www.havalklerksdorp.megatheron.co.za/"
                        target="_blank"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          Haval Klerksdorp
                        </h1>
                      </a>
                      <a
                        href="https://www.havallichtenburg.megatheron.co.za/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          Haval Lichtenburg
                        </h1>
                      </a>
                      <a
                        href="https://www.havalpotchefstroom.megatheron.co.za/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          Haval Potchefstroom
                        </h1>
                      </a>
                      <a
                        href="https://gwmmahikeng.megatheron.co.za/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            marginBottom: "-0.5em",
                          }}
                        >
                          Haval Mahikeng
                        </h1>
                      </a>
                    </span>
                  </div>
                </Col>
                <Col
                  span={12}
                  style={{
                    marginTop: "3em",
                  }}
                >
                  <img
                    src="/images/map-img.webp"
                    width={671}
                    height={442}
                    style={{ margin: "auto" }}
                    alt="Sider-Image"
                  />
                </Col>
              </Row>
            </Fade>
          </div>
        ) : (
          <div className="mobile-view">
            <div
              style={{
                backgroundImage: `url("/images/gwm-background-img.jpg")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100vw",
                margin: "auto",
                height: "16em",
                marginTop: "5.5em",
              }}
            ></div>

            <Row id="brands"
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "2em",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <Fade delay={300} bottom>
                  <h1
                    style={{
                      fontSize: "30px",
                      color: "black",
                      fontWeight: 600,
                    }}
                  >
                    Explore The GWM Brands
                  </h1>
                </Fade>
              </Col>
            </Row>
            <Fade delay={600} bottom>
              <Row

                // gutter={16}
                style={{
                  textAlign: "center",
                  maxWidth: "90em",
                  margin: "auto",
                }}
              >
                <div class="grid" style={{ margin: "auto", textAlign: "center" }}>
                  <figure class="effect-sadie">
                    <img
                      src="/images/gwm-hover-back.webp"
                      alt="img02"
                      style={{ opacity: 0 }}
                    />
                    <figcaption>
                      <h2>
                        {" "}
                        <img
                          src="/images/gwm-logo-back.png"
                          width={150}
                          style={{ margin: "auto", width: "4em" }}
                          alt="GWM-Logo"
                        />
                      </h2>
                      <p>
                        <ul>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "-1em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://gwmklerksdorp.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Klerksdorp
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.gwmpotchefstroom.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Potchefstroom
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.gwmmahikeng.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Mahikeng
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.gwmlichtenburg.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Lichtenburg
                            </a>
                          </li>
                        </ul>
                      </p>
                    </figcaption>
                  </figure>
                  <figure class="effect-sadie">
                    <img
                      src="/images/haval-hover-back.webp"
                      alt="img14"
                      style={{ opacity: 0 }}
                    />
                    <figcaption2>
                      <h2>
                        {" "}
                        <img
                          src="/images/haval-logo.svg"
                          style={{ margin: "auto", width: "6em" }}
                          alt="GWM-Logo"
                        />
                      </h2>
                      <p>
                        <ul>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "-1em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://havalklerksdorp.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Klerksdorp
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.havalpotchefstroom.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Potchefstroom
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.havalmahikeng.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Mahikeng
                            </a>
                          </li>
                          <li
                            //onClick={onClickBranch}
                            className="branch-name"
                            style={{
                              textAlign: "left",
                              marginTop: "0em",
                              fontSize: "20px",
                            }}
                          >
                            <a
                              target="_blank"
                              href="https://www.havallichtenburg.megatheron.co.za/"
                              style={{ fontSize: "20px", color: "white" }}
                            >
                              Lichtenburg
                            </a>
                          </li>
                        </ul>
                      </p>
                    </figcaption2>
                  </figure>
                </div>
              </Row>
            </Fade>
            <Row
              style={{
                maxWidth: "100vw",
                margin: "auto",
                marginTop: "3em",
                backgroundColor: "#282725",
                paddingTop: "3em",
                paddingBottom: "3em",
                paddingLeft: "1em",
                paddingRight: "1em",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                }}
              >
                <Col
                  span={24}
                  style={{
                    backgroundImage: `url("/images/car-img.webp")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    margin: "auto",
                    height: "23em",
                  }}
                ></Col>
                <Col
                  span={24}
                  style={{
                    paddingTop: "2em",
                  }}
                >
                  <Fade delay={300} bottom>
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#e30613",
                      }}
                    >
                      |{" "}
                      <span
                        style={{
                          paddingTop: "0.5em",
                        }}
                      >
                        CARS.CO.ZA CONSUMER AWARDS{" "}
                      </span>
                    </h1>
                    <h1
                      style={{
                        fontSize: 25,
                        fontWeight: 600,
                        color: "white",
                        lineHeight: "1.18",
                      }}
                    >
                      GWM celebrates four semi-finalists in Cars.co.za <br />
                      Consumer Awards
                    </h1>
                    <p
                      style={{
                        fontSize: 15,
                        color: "white",
                      }}
                    >
                      GWM is celebrating the selection of four models within the
                      manufacturer’s comprehensive range in South Africa as
                      semi-finalists in the recently announced 2023/24 Cars.co.za
                      Consumer Awards motoring awards programme.
                      <br />
                      <br />
                      The Cars.co.za Consumer Awards recognises the best
                      purchasing propositions in 13 categories of the South
                      African new-vehicle market from all vehicles available in
                      the South African market on September 1, 2023. Two models
                      from the best-selling HAVAL JOLION range were chosen, as
                      well as the HAVAL H6 HEV and ORA 03 Super Lux – giving more
                      credence to GWM’s position as one of the top ten
                      manufacturers on the monthly vehicle sales charts.
                    </p>
                  </Fade>
                </Col>
              </div>
            </Row>
            <Row
              id="about"
              style={{
                maxWidth: "90em",
                margin: "auto",
                marginTop: "3em",
                paddingLeft: "1em",
                paddingRight: "1em",
                textAlign: "center",
                paddingBottom: "3em",
              }}
            >
              <Col span={24}>
                <h1
                  style={{
                    fontSize: 15,
                    color: "#858690",
                    padding: "0em",
                  }}
                >
                  <span
                    style={{
                      fontSize: 25,
                      color: "black",
                    }}
                  >
                    |
                  </span>{" "}
                  ABOUT COMPANY{" "}
                </h1>
                <h1
                  style={{
                    fontSize: 25,
                    fontWeight: 600,
                    marginBottom: "0em",
                  }}
                >
                  GREAT WALL MOTOR COMPANY LIMITED
                </h1>
              </Col>
              <Col span={24}>
                <p
                  style={{
                    fontSize: 15,
                    color: "black",
                  }}
                >
                  GWM has created an energy-intelligence-oriented forest
                  ecosystem, established the parallel development of hybrid,
                  pure electric, and hydrogen energy, and carried out the layout
                  of the entire industry chain in terms of intelligent driving,
                  intelligent cockpit, and intelligent chassis, and built an
                  industry-leading energy system of "photovoltaic + distributed
                  energy storage + centralized energy storage" has completed the
                  full value chain layout of "solar
                  energy-battery-hydrogen-vehicle power".
                </p>
              </Col>
            </Row>
            <div
              style={{
                width: "90%",
                borderTop: "1px solid #858690",
                margin: "auto",
              }}
            />
            <Row
              style={{
                maxWidth: "90em",
                margin: "auto",
                textAlign: "center",
                paddingLeft: "1em",
                paddingRight: "1em",
              }}
            >
              <Col
                span={24}
                style={{
                  paddingTop: "2em",
                  paddingBottom: "2em",
                }}
              >
                <h1
                  style={{
                    fontSize: 25,
                    fontWeight: 600,
                    marginBottom: "0em",
                    textTransform: "uppercase"
                  }}
                >
                  Embark on a Journey of Innovation
                </h1>
                <p
                  style={{
                    fontSize: 15,
                    color: "black",
                  }}
                >
                  Experience groundbreaking technology and cutting-edge advancements in automotive engineering that drive our commitment to excellence.
                </p>
              </Col>
            </Row>
            <div
              style={{
                width: "90%",
                borderTop: "1px solid #858690",
                margin: "auto",
              }}
            />
            <Row
              style={{
                maxWidth: "90em",
                margin: "auto",
                textAlign: "center",
                paddingLeft: "1em",
                paddingRight: "1em",
              }}
            >
              <Col
                span={24}
                style={{
                  paddingTop: "2em",
                  paddingBottom: "2em",
                }}
              >
                <h1
                  style={{
                    fontSize: 25,
                    fontWeight: 600,
                    marginBottom: "0em",
                  }}
                >
                  DISTRIBUTION NETWORK
                </h1>
                <p
                  style={{
                    fontSize: 15,
                    color: "black",
                  }}
                >
                  Discover our range of vehicles at GWM and HAVAL branches in Klerksdorp, Lichtenburg, Potchefstroom, and Mahikeng.
                  Our extensive distribution network ensures unparalleled access to our products and services.
                </p>
              </Col>
            </Row>
            <div
              style={{
                width: "90%",
                borderTop: "1px solid #858690",
                margin: "auto",
              }}
            />
            <Row
              style={{
                maxWidth: "90em",
                margin: "auto",
                textAlign: "center",
                marginTop: "1em",
                paddingLeft: "1em",
                paddingRight: "1em",
                paddingBottom: "4em",
              }}
            >
              <Col span={24}>
                <h1
                  style={{
                    fontSize: 15,
                    color: "#858690",
                  }}
                >
                  <span
                    style={{
                      fontSize: 25,
                      color: "black",
                    }}
                  >
                    |
                  </span>{" "}
                  STEP INTO OUR BRANCHES
                </h1>
                <p
                  style={{
                    fontSize: 15,
                    color: "black",
                  }}
                >
                  Discover a World of Automotive Excellence at Our GWM and HAVAL Branches.
                </p>
                <div
                  style={{
                    marginTop: "3em",
                  }}
                >
                  <a
                    href="https://gwmklerksdorp.megatheron.co.za/"
                    target="_blank"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      GWM Klerksdorp
                    </h1>
                  </a>
                  <a
                    href="https://gwmlichtenburg.megatheron.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      GWM Lichtenburg
                    </h1>
                  </a>
                  <a
                    href="https://gwmpotchefstroom.megatheron.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      GWM Potchefstroom
                    </h1>
                  </a>
                  <a
                    href="https://gwmmahikeng.megatheron.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                      }}
                    >
                      GWM Mahikeng
                    </h1>
                  </a>
                  <div
                    style={{
                      width: "75%",
                      borderTop: "1px solid #858690",
                      margin: "auto",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  />
                  <a
                    href="https://www.havalklerksdorp.megatheron.co.za/"
                    target="_blank"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      Haval Klerksdorp
                    </h1>
                  </a>
                  <a
                    href="https://www.havallichtenburg.megatheron.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      Haval Lichtenburg
                    </h1>
                  </a>
                  <a
                    href="https://www.havalpotchefstroom.megatheron.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      Haval Potchefstroom
                    </h1>
                  </a>
                  <a
                    href="https://gwmmahikeng.megatheron.co.za/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h1
                      style={{
                        fontSize: 20,
                        fontWeight: 600,
                        marginBottom: "-0.5em",
                      }}
                    >
                      Haval Mahikeng
                    </h1>
                  </a>
                </div>
              </Col>
              <Col
                span={24}
                style={{
                  marginTop: "4em",
                }}
              >
                <img
                  src="/images/map-img.webp"
                  width={380}
                  height={250}
                  alt="Sider-Image"
                />
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;
    return (
      <Spin spinning={this.state.loading}>
        <div
          id="wrap"
        // className='color1-inher'
        >
          <Header />

          {location.pathname === "/home" || location.pathname === "/"
            ? this.renderSearchBar()
            : null}
          <div id="wrap-body" className="p-t-lg-45">
            <div className="container-custom">{this.props.children}</div>
          </div>
          <Footer />
        </div>
      </Spin>
    );
  }
}
export default withRouter(SharedLayout);
