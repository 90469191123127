import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  BackTop,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    window.updateCarousel();
    window.reRenderRangeSlider();
    var stock = this.props.stock;
    fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
    //if (
    //  this.props.wishlistproducts.filter(function (e) {
    //    return e._id === stock._id;
    //  }).length === 1
    //) {
    //  this.setState({ isInWishList: true });
    //}
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "black",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];

    const vehicleRangeSettings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <div className="wrap-body-inner desktop-view" id="NewVehicles">
          <div>
            {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
            {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          </div>
          <div
            className="row"
            style={{
              backgroundImage:
                "url(" + `/images/haval-ranges-background-image.webp` + ")",
              backgroundSize: "100vw",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              height: "100%",
            }}
          >


          </div>

        </div>
        <div className="wrap-body-inner mobile-view">
          {/* <div> */}
          {/*<section className='block-sl'>
            <div className='container-custom'>
              <div className='row'>
                <div className='col-sm-5 col-md-5 col-lg-5'>
                  <div className='banner-item-home banner-2x-home no-bg color-inher-home'>
                    <h1 className='f-weight-600'>
                      PURCHASE YOUR <br />
                    </h1>
                    <h1 style={{ color: "red" }}>PERFECT STOCK</h1>
                    <p>
                      Mega Theron Group makes it easy to drive your dream
                      stock at the best prices possible.
                    </p>
                    <Link
                  to={"/stock"}
                  className="ht-btn ht-btn-default ht-btn-2x m-t-lg-35"
                >
                  View all stock
                </Link>
                  </div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <img
                    className='banner-img-home'
                    style={{ width: "50em" }}
                      src={`${process.env.PUBLIC_URL}/images/home-banner.png`}
                    alt='logo'
                  />
                </div>
              </div>
            </div>
          </section>*/}
          {/*<SearchBarHome filters={this.getFilters.bind(this)} />*/}
          {/* </div> */}

        </div>
        <BackTop />
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);
