import React, { useState, Component } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, Button, Modal, Select, Card } from "antd";
import { Link } from "react-router-dom";
import { data } from "jquery";
//import { Modal as SuccessModal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";
const { Option, OptGroup } = Select;
const { Meta } = Card;

const { TextArea } = Input;
const selectBefore = (
  <Form.Item
    name='title'
    rules={[{ required: true, message: false }]}
    style={{ marginBottom: "0px" }}
  >
    <Select placeholder='Title' className='select-before'>
      <Option value='Mrs'>Mr</Option>
      <Option value='Miss'>Miss</Option>
    </Select>
  </Form.Item>
);
const StyledInput = styled(Input)`

  //padding-left: 1.2em !important;
  height: 60px !important;

    box-shadow:none !important;
  background-color: transparent !important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid white!important;
  border-radius: 0px;
  color: white!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  .ant-input-group-addon .ant-select {
    margin: 0em !important;
}
.ant-input-affix-wrapper {
  background-color: black !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px !important;
  color: white !important;
}
.ant-select-arrow {

  color: white !important;

}
.ant-input-group-addon {
  padding: 0px !important;
  color: white !important;
  background-color: black !important;
  border: 0px solid white !important;
  border-right: 1px solid white !important;
 
}
.ant-input-affix-wrapper {

  height: 58px !important;
  border: none !important;}
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: white !important;

    box-shadow:none !important;
}
  :active{
    border-color: white !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: white !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const StyledTextArea = styled(TextArea)`
// padding-left:0.4em !important;
  box-shadow:none !important;
background-color: black!important;
background-image: none;
border: none;
border: 0px solid black !important;border-color:white!important;
border: 1px solid white!important;
border-radius: 0px;
color: white !important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  black !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
  background-color: black!important;
  color: white !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: white!important;

  box-shadow:none !important;
}
:active{
  border-color: white !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: white !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 60px !important;
    padding: 5px 11px !important;
    border: 1px solid white !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: white !important;
  }
  .ant-select-multiple .ant-select-selector {
    height: 60px !important;
  }

  border: 1px solid lightgrey !important;
  border-radius: 0em !important;
  height: 60px !important;
  box-shadow: none !important;
  :hover {
    border-color: white !important;

    box-shadow: none !important;
  }
  :active {
    border-color: white !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: white !important;

    box-shadow: none !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 60px !important;
}
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
      color: white !important;
    border-color: white !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedBranch: "",
      // klerksdorpMails: "ianels182@gmail.com",
      // klerksdorpMail: "service@megatheron.co.za",
      potchefstroomMail: "potchservice@megatheron.co.za",
      potchefstroomMail1: "potchservice@megatheron.co.za",
      mahikengMail: "service1@megatheron.co.za",
      mahikengMail2: "service1@megatheron.co.za",
      mahikengMail3: "service1@megatheron.co.za",
      lichtenburgMail: "vanaarde@megatheron.co.za",
      klerksdorpMail: "venicio@megatheron.co.za",
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  //onFinish = (values) => {
  //  (async () => {
  //    const rawResponse = await fetch(
  //      "https://leadsv3.cmscloud.co.za/api/lead/saveleadasync",
  //      {
  //        method: "POST",
  //        headers: {
  //          Accept: "application/json",
  //          "Content-Type": "application/json",
  //          Authorization: "Bearer " + "9D20B459-5A85-45C4-92B4-7D0F7E7FA50B",
  //        },
  //        body: JSON.stringify({
  //          //title: "React POST Request Example",
  //          lead: {
  //            dealerRef: "MT001",
  //            dealerFloor: "USED",
  //            //dealerSalesPerson: "",
  //            //region: "Gauteng",
  //            source: "MT-DEALERWEBSITE",
  //            transactionID: data.id,
  //            //details: values.details,
  //          },
  //          contact: {
  //            email: values.email,
  //            cellPhone: values.cellPhone,
  //            surname: values.surname,
  //            firstName: values.firstName,
  //            title: values.title,
  //          },
  //          //referrer: {
  //          //  email: values.email,
  //          //  cellPhone: values.cellPhone,
  //          //  surname: values.surname,
  //          //  firstName: values.firstName,
  //          //  title: values.title,
  //          //},
  //        }),
  //      }
  //    );
  //    const content = await rawResponse.json();

  //    console.log(content);
  //  })();
  //};
  //onFinish = (values) => {
  //  // Simple POST request with a JSON body using fetch
  //  const requestOptions = {
  //    method: "POST",
  //    headers: {
  //      "Content-Type": "application/json",
  //      Authorization: "Bearer " + "9D20B459-5A85-45C4-92B4-7D0F7E7FA50B",
  //    },
  //    body: JSON.stringify({
  //      //title: "React POST Request Example",
  //      lead: {
  //        dealerRef: "MT001",
  //        dealerFloor: "USED",
  //        //dealerSalesPerson: "",
  //        //region: "Gauteng",
  //        source: "MT-DEALERWEBSITE",
  //        transactionID: data.id,
  //        //details: values.details,
  //      },
  //      contact: {
  //        email: values.email,
  //        cellPhone: values.cellPhone,
  //        surname: values.surname,
  //        firstName: values.firstName,
  //        title: values.title,
  //      },
  //      //referrer: {
  //      //  email: values.email,
  //      //  cellPhone: values.cellPhone,
  //      //  surname: values.surname,
  //      //  firstName: values.firstName,
  //      //  title: values.title,
  //      //},
  //    }),
  //  };
  //  fetch(
  //    "https://aws-stage-leadsv3.cmscloud.co.za/api/lead/savelead",
  //    requestOptions
  //  )
  //    .then((response) => response.json())
  //    .then(async (res) => {
  //      if (!res.ok) {
  //        console.log("handle error");
  //        this.setState({
  //          code: "01",
  //          leadReference: "",
  //          status: "Failed",
  //          message: "Rejected - Lead DealerRef Invalid: 123M",
  //        });
  //      }
  //      console.log("handle success");
  //      this.setState({
  //        visible: true,
  //        submitDisabled: true,
  //        code: "00",
  //        leadReference: "fe7521e8-d48e-420d-a8d5-c9821a267a7c",
  //        status: "Success",
  //        message: "",
  //      });
  //    });
  //};
  onFinish = (values) => {
    const MSG = {
      to: values.branch,
      from: "info@mtgroup.co.za",
      subject: "New Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Contact Enquiry from <br /> Haval website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.title} ${values.firstName} ${values.surname}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.cellPhone}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Enquiry </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.details}


                 
                      
          
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Haval</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        title: "",
        firstName: "",
        surname: "",
        cellPhone: "",
        email: "",
        details: "",
      });
    });
  };
  //onFinish = (values) => {
  //  let api_url_stage =
  //    "https://aws-stage-leadsv3.cmscloud.co.za/api/lead/savelead";
  //  let api_token_stage = "9D20B459-5A85-45C4-92B4-7D0F7E7FA50B";
  //  let api_url_prod = "https://leadsv3.cmscloud.co.za/api/lead/savelead";
  //  let api_token_prod = "B9CDC074-49D8-401F-81BB-4DE4F6B83CA1";
  //  // POST request using fetch with set headers
  //  const requestOptions = {
  //    method: "POST",
  //    headers: {
  //      "Content-Type": "application/json",
  //      Authorization: api_token_stage,
  //    },
  //    body: JSON.stringify({
  //      lead: {
  //        dealerRef: "MT001",
  //        dealerFloor: "USED",
  //        source: "MT-DEALERWEBSITE",
  //        //transactionID: data.id,
  //      },
  //      contact: {
  //        email: values.email,
  //        cellPhone: values.cellPhone,
  //        surname: values.surname,
  //        firstName: values.firstName,
  //        title: values.title,
  //      },
  //    }),
  //  };
  //  fetch(api_url_stage, requestOptions)
  //    .then((response) => response.json())
  //    .then((res) => {
  //      // Unfortunately, fetch doesn't send (404 error) into the cache itself
  //      // You have to send it, as I have done below
  //      if (res.status >= 400) {
  //        throw new Error("Server responds with error!");
  //      }
  //      return res.json();
  //    })
  //    .then(() =>
  //      this.setState({
  //        code: "00",
  //        leadReference: "fe7521e8-d48e-420d-a8d5-c9821a267a7c",
  //        status: "Success",
  //        message: "",
  //      })
  //    );
  //  (err) => {
  //    this.setState({
  //      err,
  //      code: "01",
  //      leadReference: "",
  //      status: "Failed",
  //      message: "Rejected - Lead DealerRef Invalid: 123M",
  //    });
  //  };
  //};
  render() {
    return (
      <div className='wrap-body-inner' style={{ margin: "auto", marginTop: "10em", maxWidth: "90em" }}>
        <img
          //className="banner-img-home"
          style={{
            width: "100%",
            // position: "absolute",

            objectFit: "cover",

          }}
          src={`${process.env.PUBLIC_URL}/images/haval-contact.jpg`}
          alt='logo'
        />
        {/*<div className="hidden-xs">
          <div className="row">
            <div className="col-lg-6">
              <ul className="ht-breadcrumb pull-left">
                <li className="home-act">
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
        </div>*/}
        <div className='banner-item banner-2x banner-bg-11 color-inher m-b-lg-0'>
          <h6 style={{ textAlign: "center", marginTop: "2em", padding: 0 }}>
            Haval Customer Care
          </h6>
          <h3 className='f-weight-300 m-b-lg-0'>
            <strong>LEAVE US A
              MESSAGE</strong>
          </h3>
          {/* <p style={{ margin: "auto", padding: 0 }}>Let us call you back</p> */}
        </div>
        <section className='block-contact m-t-lg-0 m-t-xs-0 p-b-lg-50'>
          <div className=''>
            <div className='row'>
              {/* <div className='col-sm-6 col-md-6 col-lg-6 m-b-xs-30'>
                <div className='contact-info p-lg-30 p-xs-15 bg1-gray-2'>
                  <div className='content'>
                    <p>
                      Leave a message, we will get back to you.
                    </p>
                    <ul className='list-default'>
                      <li>
                        <i className='fa fa-map-marker' aria-hidden='true'></i>
                        {"  "}30 Leask St, Klerksdorp Central, Klerksdorp, 2570
                      </li>
                      <a href={"tel:018 518 0111"}>
                        <li>
                          <i className='fa fa-phone'></i>
                          {"  "}018 518 0111
                        </li>{" "}
                      </a>
                      <a href={"mailto:info@megatheron.co.za"}>
                        <li>
                          <i className='fa fa-envelope-o'></i>
                          <strong> General Enquiries:</strong> {"  "}
                          info@megatheron.co.za
                        </li>
                      </a>
                      <a href={"mailto:wecare@megatheron.co.za"}>
                        <li>
                          <i className='fa fa-globe'></i>
                          <strong> Customer Care:</strong> {"  "}
                          wecare@megatheron.co.za
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className='col-sm-12 col-md-12 col-lg-12'>
                <div className='contact-form p-lg-30 p-xs-15  bg1-gray-2'>
                  <Form
                    onFinish={this.handleSubmit}
                    initialValues={{
                      remember: true,
                    }}
                    name='wrap'
                    labelCol={{ flex: "110px" }}
                    labelAlign='left'
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}
                  >
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                      <Form.Item
                        name='firstName'
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledInput
                          addonBefore={selectBefore}
                          placeholder='Name'
                        />
                      </Form.Item>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                      <Form.Item
                        name='surname'
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledInput placeholder='Surname' />
                      </Form.Item>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Form.Item
                        name='cellPhone'
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledInput placeholder='Number' />
                      </Form.Item>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Form.Item
                        name='email'
                        rules={[{ required: false, message: false, type: "email" }]}
                        hasFeedback
                      >
                        <StyledInput placeholder='Email' />
                      </Form.Item>
                    </div>

                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Form.Item
                        name='branch'
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledSelect
                          bordered={false}
                          style={{
                            border: "1px solid black",
                            borderRadius: "0em",
                            paddingTop: "0.4em",
                            color: "white"
                          }}
                          placeholder='Branch'
                        //onChange={this.setCarEngineCapacity}
                        >
                          <OptGroup label='Klerksdorp'>
                            <Option
                              key={1}
                              value={this.state.klerksdorpMail}
                            >
                              Haval (Klerksdorp)
                            </Option>
                          </OptGroup>
                          <OptGroup label='Potchefstroom'>
                            <Option
                              key={1}
                              value={this.state.potchefstroomMail1}
                            >
                              Haval (Potchefstroom)
                            </Option>
                          </OptGroup>
                          <OptGroup label='Mahikeng'>
                            <Option
                              key={2}
                              value={this.state.mahikengMail3}
                            >
                              Haval (Mahikeng)
                            </Option>
                          </OptGroup>
                          <OptGroup label='Lichtenburg'>
                            <Option
                              key={1}
                              value={this.state.lichtenburgMail}
                            >
                              Haval (Lichtenburg)
                            </Option>
                          </OptGroup>
                        </StyledSelect>
                      </Form.Item>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Form.Item
                        name='details'
                        rules={[{ required: true, message: false }]}
                        hasFeedback
                      >
                        <StyledTextArea
                          placeholder='Enquiry'
                          showCount
                          maxLength={100}
                          style={{ height: 120, border: "1px solid black" }}
                        //onChange={onChange}
                        />
                      </Form.Item>
                    </div>
                    <div className='col-lg-12 m-t-lg-12 text-center'>
                      <Form.Item>
                        <Button
                          style={{
                            fontFamily: "Arial",
                            border: "1px solid #CD2934",
                            fontSize: "15px",
                            fontWeight: 600,
                            borderRadius: "4em",
                            backgroundColor: "#CD2934",
                            color: "white",
                            height: "55px",
                            paddingLeft: "3em",
                            paddingRight: "3em",
                            textShadow: "none",
                            margin: "auto",
                            marginTop: "1em",
                            float: window.isPhone ? "" : "right"
                          }}
                          type='primary'
                          htmlType='submit'
                        >CONTACT ME
                        </Button>{" "}

                        <p
                          style={{
                            color: "white",
                            marginTop: "1em",
                            fontSize: "13px",
                            lineHeight: "1.5em",
                            float: "left"
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "white" }} to='/terms'>
                              Terms of Use
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "white" }} to='/privacy-policy'>
                              Privacy Statement
                            </Link>
                          </span>
                          .
                        </p>{" "}
                      </Form.Item>{" "}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <Row style={{ marginTop: "1em" }}>
              <Col sm={24} md={10} style={{ margin: "auto", width: window.isPhone ? "90vw" : "", marginTop: "2.5em" }}>
                <Card
                  hoverable
                  style={{

                  }}
                  cover={<iframe
                    // style={{
                    //   borderRadius: "0em",
                    //   marginLeft: "-0em",
                    //   marginTop: "5em",
                    //   width: "100vw",
                    // }}
                    className='contact-hidden'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.17124836777!2d26.664352415044014!3d-26.866299783147625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e96f10578eb30e7%3A0xa5b72df90734ef7d!2s30%20Leask%20St%2C%20Klerksdorp%20Central%2C%20Klerksdorp%2C%202570!5e0!3m2!1sen!2sza!4v1659003956408!5m2!1sen!2sza'
                    // height='300'
                    title='map'
                    frameBorder='0'
                    allowFullScreen=''
                    aria-hidden='false'
                    tabIndex='0'
                  ></iframe>}
                >
                  <Meta title="HAVAL Klerksdorp" description={<span>30 Leask Street, Klerksdorp, 2571<br />Tel: (018) 518 0111</span>} />
                </Card>
              </Col>
              <Col sm={0} md={2}></Col>
              <Col sm={24} md={10} style={{ margin: "auto", width: window.isPhone ? "90vw" : "", marginTop: "2.5em" }}>
                <Card
                  hoverable
                  style={{

                  }}
                  cover={<iframe
                    // style={{
                    //   borderRadius: "0em",
                    //   marginLeft: "-0em",
                    //   marginTop: "5em",
                    //   width: "100vw",
                    // }}
                    className='contact-hidden'

                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.937981996891!2d27.096479715567927!3d-26.714428191398838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e96813c3fbd16cf%3A0x6be80da948570e27!2s33%20Nelson%20Mandela%20Dr%2C%20Potchefstroom%2C%202520!5e0!3m2!1sen!2sza!4v1664267015462!5m2!1sen!2sza'
                    // height='300'
                    title='map'
                    frameBorder='0'
                    allowFullScreen=''
                    aria-hidden='false'
                    tabIndex='0'
                  ></iframe>}
                >
                  <Meta title="HAVAL Potchefstroom" description={<span>33 Nelson Mandela Drive, Potchefstroom, 2531<br />Tel: (018) 518 0888</span>} />
                </Card>
              </Col>
            </Row>
            <Row >
              <Col sm={24} md={10} style={{ margin: "auto", width: window.isPhone ? "90vw" : "", maxWidth: "90vw", marginTop: "2.5em" }}>
                <Card
                  hoverable
                  style={{

                  }}
                  cover={<iframe
                    // style={{
                    //   borderRadius: "0em",
                    //   marginLeft: "-0em",
                    //   marginTop: "5em",
                    //   width: "100vw",
                    // }}

                    className='contact-hidden'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1795.5122560558116!2d25.632731658216343!3d-25.835737581785153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ea2cb7125759d2f%3A0x8c1d48d04621d228!2sGWM%20Mahikeng!5e0!3m2!1sen!2sza!4v1664267148954!5m2!1sen!2sza'
                    // height='300'
                    title='map'
                    frameBorder='0'
                    allowFullScreen=''
                    aria-hidden='false'
                    tabIndex='0'
                  ></iframe>}
                >
                  <Meta title="HAVAL Mahikeng" description={<span>Shop 42, The Crossing, C/O First & Sekame St, Mahikeng 2735<br />Tel: (018) 518 0994</span>} />
                </Card>
              </Col>
              <Col sm={0} md={2}></Col>
              <Col sm={24} md={10} style={{ margin: "auto", width: window.isPhone ? "90vw" : "", marginTop: "2.5em" }}>
                <Card
                  hoverable
                  style={{

                  }}
                  cover={<iframe
                    // style={{
                    //   borderRadius: "0em",
                    //   marginLeft: "-0em",
                    //   marginTop: "5em",
                    //   width: "100vw",
                    // }}
                    className='contact-hidden'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.5768223049477!2d26.149305315556404!3d-26.145337467933484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ebd63ee3d692aa9%3A0x7d243cabc4872572!2sHaval%20Lichtenburg!5e0!3m2!1sen!2sza!4v1664267252685!5m2!1sen!2sza'
                    // height='300'
                    title='map'
                    frameBorder='0'
                    allowFullScreen=''
                    aria-hidden='false'
                    tabIndex='0'
                  ></iframe>}
                >
                  <Meta title="HAVAL Lichtenburg" description={<span>4 Thabo Mbeki Drive, Lichtenburg, 2740<br />Tel: (018) 518 0889</span>} />
                </Card>
              </Col>
            </Row>
          </div >
        </section > {" "}
        < Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
            style={{ width: "65%", margin: "auto", marginTop: "2em", }}
          />
          <br />
          <br />
          <br />
          <span
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting Haval.

            <br />

            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </span>{" "}
          <br />
        </Modal >
      </div >
    );
  }
}
export default ContactUs;
