import React, { Component } from "react";
import { Row, Col, Divider, Input, Button } from "antd";
import { Link } from "react-router-dom";

class LegalNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='wrap-body-inner'>
        <div>
          <div className='row'>
            <div>

              <div
                id='home'
                className='home-div'
                style={{
                  //maxHeight: "100em",
                  marginTop: "12em",
                  //width: "100vw",
                }}
              >
                {" "}
                <Row
                  className='home-row'
                  style={{
                    maxWidth: "90em",
                    margin: "auto",
                  }}
                >
                  <Col
                    sm={24}
                    md={24}
                    style={{
                      margin: "auto",
                      textAlign: "left",
                      marginTop: "3em",
                      marginBottom: "3em",
                    }}
                  >
                    <h2
                      style={{
                        marginTop: "0em",
                        color: "#3F3F3F",
                        fontWeight: 700,
                        fontSize: "30px",
                        padding: "30px",
                        paddingBottom: 20,
                        paddingTop: 0,
                        lineHeight: "1em",
                      }}
                    >
                      POPIA MANUAL
                    </h2>

                    <div
                      style={{
                        margin: "auto",
                        fontWeight: 100,
                        // color: "white",
                        fontWeight: 400,
                        fontSize: "17px",
                        maxWidth: "90em",
                        padding: "30px",
                        paddingTop: 0,
                        textAlign: "left",
                      }}
                    >
                      {/*Date:	*/}
                      PURPOSE OF THIS MANUAL:
                      <br />
                      <br />
                      This MANUAL on data protection explains what information
                      Mega Theron Group collects while you are visiting the
                      website and how this information is used.
                      <br />
                      We place great importance on the security of all personal
                      data associated with our users. We have security measures
                      in place to protect against the loss, misuse and
                      alteration of personal information.
                      <br />
                      Our duty at Mega Theron Group is to protect the
                      information received, and not to share it with third
                      parties without consent, to keep the information received
                      confidential and to act within the scope of the POPIA.{" "}
                      <br />
                      <br />
                      BACKGROUND OF MEGA THERON GROUP:
                      <br />
                      <br />
                      Mega Theron Group will help you find your dream vehicle.
                      We offer top quality vehicles at affordable prices,
                      quality services and the best advice. We strive in
                      building an on-going relationship based on honesty and
                      integrity. Mega Theron Group is situated in Klerksdorp, in
                      the heart of the North West Province. We believe that you
                      will walk in as a stranger and leave as part of our
                      family. The growth of Mega Theron Group through the years
                      is proof of personal service and customer care. We at Mega
                      Theron Group falls under the Retail sector as we sell
                      passenger and commercial vehicles in which it includes the
                      entry level, sports utility vehicles, luxury, light
                      commercial, and heavy-duty vehicles. It also has a number
                      of pre-owned, passenger vehicle, and commercial vehicle.
                      <br />
                      <br />
                      DEFINITIONS:
                      <br />
                      <br />
                      POPIA establishes the rights and duties that are created
                      to safeguard personal data. In terms of POPIA, the needs
                      of organisations to collect and use personal data for
                      business and other purposes are balanced against the right
                      of individuals to have their right of privacy, and their
                      personal details. <br />
                      <br />
                      For the purpose of the Policy, please take note of the
                      definitions of the following words which will be used
                      throughout this Policy:
                      <br />
                      <br />
                      "Company, we, us or our" means <br />
                      <br />
                      "We, us or our" means: <br />
                      <br />
                      1. Mega Theron Group (Pty) Ltd with Reg. № 2020/570691/07
                      and Trading As:
                      <br />
                      • GWM & HAVAL Klerksdorp situated at 30 Leask Street,
                      Klerksdorp, 2571
                      <br />
                      • GWM & HAVAL Potchefstroom situated at 33 Nelson Mandela
                      Drive, Potchefstroom, 2531
                      <br />
                      • Kia & Mahindra Mahikeng situated at 2320 Aerodrome Road,
                      Mahikeng, 2735
                      <br />
                      • GWM & HAVAL Mahikeng situated at Shop 42, The Crossing,
                      C/O First & Sekame St, Mahikeng 2735
                      <br />
                      • GWM & HAVAL Lichtenburg situated at 4 Thabo Mbeki Drive,
                      Lichtenburg, 2740
                      <br />
                      <br />
                      2. Mega Theron Group (Pty) Ltd with Reg. № 2020/086396/07
                      and situated at 13 Joe Slovo Road, Freemanville,
                      Klerksdorp, 2571
                      <br />
                      <br />
                      3. MTG Klerksdorp (Pty) Ltd T/A Chery Klerksdorp with Reg.
                      № 2021/990251/07 and situated at C/O Bram Fischer & Leask
                      Street, Klerksdorp, 2571
                      <br />
                      <br />
                      "Our site or our website" means the site on which these
                      terms and conditions are displayed, including, but not
                      limited to the following websites (www.megatheron.co.za)
                      <br />
                      <br />
                      "You or your" means the person accessing or using our
                      site.
                      <br />
                      <br />
                      "Data Subject" means the person to whom personal
                      information relates to (belong to).
                      <br />
                      “Information Officer” means the head of a private body as
                      contemplated in section 1 of the Promotion of Access to
                      Information Act.
                      <br />
                      <br />
                      "Personal Information" means information relating to an
                      identifiable, living, natural person, and where it is
                      applicable, an identifiable, existing juristic person,
                      including, but not limited to:
                      <br />
                      <br />
                      • information relating to the race, gender, pregnancy,
                      marital status, national, ethnic or social origin, sexual
                      orientation, age, physical or mental health, well-being,
                      disability, religion, conscience, belief, culture,
                      language and birth of the person;
                      <br />
                      • information relating to the education or the medical,
                      financial, criminal or employment history of the person;
                      <br />
                      • any identifying number, symbol, e-mail address, physical
                      address, telephone number, location information, online
                      identifier or other particular assignment to the person;
                      <br />
                      • the biometric information of the person;
                      <br />
                      • the personal opinions, views or preferences of the
                      person;
                      <br />
                      • correspondence sent by the person that is implicitly or
                      explicitly of a private or confidential nature or further
                      correspondence that would reveal the contents of the
                      original correspondence;
                      <br />
                      • the views or opinions of another individual about the
                      person; and the name of the person if it appears with
                      other personal information relating to the person or if
                      the disclosure of the name itself would reveal information
                      about the person;
                      <br />
                      • information about your next of kin and or dependants;
                      <br />
                      "Processing" means any operation or activity or any set of
                      operations, whether or not by automatic means, concerning
                      personal information, including:
                      <br />
                      • the collection, receipt, recording, organisation,
                      collation, storage, updating or modification, retrieval,
                      alteration, consultation or use;
                      <br />
                      • dissemination by means of transmission, distribution or
                      making available in any other form; or merging, linking,
                      as well as restriction, degradation, erasure or
                      destruction of information;
                      <br />
                      “Purpose” means the reason why your Personal Information
                      is collected, processed and stored by us;
                      <br />
                      "Responsible Party” means, the company, which, alone or in
                      conjunction with others, determines the purpose of and
                      means for processing personal information.
                      <br />
                      <br />
                      WHAT INFORMATION DO WE COLLECT? <br />
                      <br />
                      Collection of Personal Information <br />
                      <br />
                      At Mega Theron Group we collect and process your Personal
                      Information mainly to provide you with access to our
                      services and products, to help us improve our offerings to
                      you, to support our contractual relationship with you and
                      for certain other purposes explained below. <br />
                      Mega Theron Group will collect personal information
                      directly or indirectly from the Data Subjects. Mega Theron
                      Group will process personal information for only the
                      purpose it was collected for and for which consent was
                      given. Where we collect Personal Information for a
                      specific purpose, we will not keep it for longer than is
                      necessary to fulfil that purpose, unless we have to keep
                      it for legitimate business or legal reasons. <br />
                      Mega Theron Group may collect further personal information
                      through email correspondence, personal meetings, and
                      telephone calls dealings with the Data Subjects. Mega
                      Theron Group may collect personal information about the
                      Data Subjects from third parties, if necessary and
                      required to perform a service as requested. By requesting
                      Mega Theron Group to deliver a service, and or engaging
                      with Mega Theron Group the Data Subject has consented to
                      the collection and processing of personal information and
                      any further collection necessary to fulfil the business
                      purpose.
                      <br />
                      The registration of the data subjects
                      <br />
                      The registration of the data subject, with the voluntary
                      indication of implied consent that the personal data, is
                      intended to enable the company to offer the data subject
                      contents or services that may only be offered to register
                      users. Registered persons are free to change or withdraw
                      their consent specified during the registration at any
                      time, or to have them completely deleted from the data
                      stock of the Responsible Party.
                      <br />
                      <br />
                      HOW WE USE YOUR INFORMATION: <br />
                      <br />
                      We will use your Personal and Non-Personal Information
                      only for the purposes for which it was collected or agreed
                      with you, for example
                      <br />
                      <br />• Evaluate the use of the site, products and
                      services; <br />
                      • For audit and record keeping purposes; <br />
                      • For market research purposes; <br />
                      • For monitoring and auditing site usage;
                      <br />
                      • Make the site easier to use and to better tailor the
                      site and our products to your interests and needs;
                      <br />
                      • Offer you the opportunity to take part in competitions
                      or promotions;
                      <br />
                      • Personalise your website experience, as well as to
                      evaluate (anonymously) statistics on website activity,
                      such as what time you visited it and if you’ve visited it
                      before. <br />
                      • To carry out our obligations arising from any contracts
                      entered into between you and us
                      <br />
                      • To contact you regarding products and services which may
                      be of interest to you, provided you have given us consent
                      to do so or you have previously requested a product or
                      service from us and the communication is relevant or
                      related to that prior request and made within any
                      timeframes established by applicable laws;
                      <br />
                      • To notify you about changes to our service;
                      <br />
                      • To respond to your queries or comments.
                      <br />
                      <br />
                      DISCLOSE OF PERSONAL INFORMATION:
                      <br />
                      <br />
                      Our Company may disclose your Personal Information to our
                      individuals who are involved in the delivery of products
                      or services. We have agreements in place to ensure that
                      they comply with these privacy terms. When we contract
                      third parties, we impose appropriate security, privacy and
                      confidentiality obligations on them to ensure that
                      Personal Information that we remain responsible for, is
                      kept secure. We will ensure that anyone to whom we pass
                      your Personal Information agrees to treat your information
                      with the same level of protection as we are. <br />
                      Each Data Subject has the right to:
                      <br />
                      <br />
                      Withdraw any of the consents given to the Company in
                      relation to the processing of their personal information
                      (however, the Company may continue to process the personal
                      information to the extent necessary to implement a
                      contract with the Data Subject, or to protect the
                      legitimate interests of the Data Subject, or to protect
                      the legitimate interests of the Company, or to comply with
                      any legal obligation);
                      <br />
                      <br />
                      Ask the Company to reveal what personal information the
                      Company has relating to the Subject and who has had access
                      to that information (in which case the Company will
                      respond as soon as reasonably practicable);
                      <br />
                      <br />
                      Ask the Company to reveal what personal information the
                      Company has relating to the Subject and who has had access
                      to that information (in which case the Company will
                      respond as soon as reasonably practicable); <br />
                      <br />
                      Ask the Company to correct or delete any of their personal
                      information, which is inaccurate, irrelevant, out of date,
                      incomplete or misleading (in which case the Company will
                      respond by altering its records, or substantiating its
                      records, as appropriate, as soon as reasonably
                      practicable), provided that the Data Subject will, on
                      request, provide all such correct and accurate personal
                      information as may be required;
                      <br />
                      <br />
                      Ask the Company to destroy or delete any of their personal
                      information if it is no longer lawful for the Company to
                      retain it (in which case the Company will respond as soon
                      as reasonably practicable, allowing time for the Company
                      to seek legal advice if necessary);
                      <br />
                      <br />
                      Object to the Company processing any of their personal
                      information where the processing is:
                      <br />
                      <br />
                      Not covered by consent; or Not necessary for carrying out
                      a contract between the Company and for service delivery
                      from the Company; or Not necessary to discharge a legal
                      obligation of the Company, and the Data Subject has
                      reasonable grounds for objection (in which case the
                      Company must cease processing the personal information);
                      <br />
                      <br />
                      Object to the Company processing any of their personal
                      information to prevent any direct marketing by the Company
                      to the Data Subject (in which case the Company must cease
                      processing the personal information for this purpose);
                      <br />
                      <br />
                      HOW LONG DOES THE COMPANY KEEP THE PERSONAL INFORMATION
                      FOR?
                      <br />
                      <br />
                      Personal information must be stored for no longer than
                      necessary for the purposes for which it is processed.
                      Therefore Mega Theron Group will retain personal
                      information for the period prescribed in the Legislation
                      and for the purpose it is required for. Personal
                      Information of data subjects will be held for a period of
                      five (5) year following the date of termination of the
                      business relationship according to applicable legislation
                      and/or requirements of the company. After this period,
                      your Personal Information will be irreversibly destroyed.
                      Mega Theron Group also stores and processes personal
                      information for so long as the Company maintains a
                      business relationship or contact with such Data Subject,
                      and for at least five years thereafter. This is a general
                      rule applied by the Company to:
                      <br />
                      <br />
                      1.1.1. Ensure that it maintains personal information of
                      all Subjects who could benefit from the Company’s
                      services; and
                      <br />
                      <br />
                      1.1.2. Carry out good record-keeping practices, in case
                      the details of any transaction are queried by a Data
                      Subject, governmental authority or regulatory body.
                      <br />
                      <br />
                      1.2. By transacting with the Company, or attempting or
                      proposing to transact with Mega Theron Group in any way,
                      you consent to the length of time for which the Company
                      keeps their personal information.
                      <br />
                      <br />
                      TO LODGE A COMPLAIN TO THE REGULATOR: <br />
                      Physical Address:
                      <br />
                      JD House, 27 Stiemens Street
                      <br />
                      Braamfontein
                      <br />
                      Johannesburg, 2001
                      <br />
                      Postal Address:
                      <br />
                      PO Box 31533
                      <br />
                      Braamfontein, Johannesburg, 2017
                      <br />
                      Tel: <br />
                      010 023 5207
                      <br />
                      Email: <br />
                      complaints.IR@justice.gov.za / inforeg@justice.gov.za.
                      <br />
                      TO LODGE A COMPLAIN TO THE INFORMATION OFFICER:
                      <br />
                      Name of information officer: <br />
                      Xander Bason, <br />
                      Email:
                      <br />
                      popi@megatheron.co.za
                    </div>
                    <Divider
                      orientation='right'
                      style={{ borderTopColor: "rgba(0, 0, 0, 0.25)" }}
                    >
                      <Link to='/home'>
                        <Button
                          style={{
                            float: "right",
                            height: "2.7em",
                            width: "10em",
                            fontSize: "17px",
                            fontWeight: 500,
                            borderRadius: "0.3em",
                            color: "white",
                            border: "none",
                            marginTop: "1em",
                            marginBottom: "1em",
                            backgroundColor: "black",
                            textShadow: "none",
                            boxShadow: "none",
                          }}
                        >
                          Back to Home
                        </Button>
                      </Link>
                    </Divider>
                  </Col>
                </Row>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegalNotice;
